

















































































import { defineComponent, reactive, watch } from "@vue/composition-api";
import { useProductAPI } from "@/api_connectors/product";
import { useEmptyObject } from "@/types/newEmptyObject"
import { Product } from "@/types/typeDefinitions";
import ProductVariantRow from "@/components/ProductVariantRow.vue";
import { useToast } from "@/composables/useToast";

export default defineComponent({
    name: "EditProduct",
    components: { ProductVariantRow },
    props: {
        productId: {
            type: String,
            required: true
        }
    },
    setup(props, context) {
        const { createProduct, getProduct, editProduct } = useProductAPI()
        const { newProduct, newVariant } = useEmptyObject()
        const { showToast } = useToast()

        const product = reactive<Product>(newProduct())
        let originalProduct = ''

        const createNewProduct = async (closeModal: Function): Promise<void> => {
            const response = await createProduct(product)

            if (!response) {
                context.emit('save-failed', 'create')
                return showToast('save-failed-toast')
            }

            Object.assign(product, newProduct())
            context.emit('product-data-changed', 'create')
            showToast('saved-toast')
            closeModal();
        }

        const editProductValues = async (closeModal: Function): Promise<void> => {
            // console.log(product)
            try {
                await editProduct(product)
            } catch (e) {
                // console.log(e)
                context.emit('save-failed', 'save')
                showToast('save-failed-toast')
                return closeModal();
            }
            context.emit('product-data-changed', 'save')
            showToast('saved-toast')
            closeModal();
        }

        const update = async (): Promise<void> => {
            if (props.productId === 'new') {
                Object.assign(product, newProduct())
            } else {
                const tempProduct = await getProduct(props.productId)
                originalProduct = JSON.stringify(tempProduct)
                // console.log(tempProduct)
                if (!tempProduct) return;
                Object.assign(product, tempProduct)
            }
            // console.log("Product", product)
        }

        const addVariant = (): void => {
            product.variants.push(newVariant())
        }

        const cancelEdit = (closeModal: Function): void => {
            if (props.productId === 'new') {
                Object.assign(product, newProduct())
                return closeModal();
            }

            try {
                const tempProduct = JSON.parse(originalProduct)
                Object.assign(product, tempProduct)
                closeModal();
            } catch (e) {
                closeModal();
            }

        }

        const deleteVariant = (variantID: string) => {
            const index = product.variants.findIndex(variant => variant.id === variantID)
            // console.log(index)
            product.variants.splice(index, 1)
            // console.log(product.variants)
        }

        watch(props, () => {
            update();
        })

        return {
            product,
            createNewProduct,
            editProductValues,
            addVariant,
            cancelEdit,
            deleteVariant
        }
    }
})
