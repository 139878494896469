


















































































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { ProductVariant } from "@/types/typeDefinitions";

export default defineComponent({
    name: "ProductVariantRow",
    props: {
        productVariant: {
            type: Object as PropType<ProductVariant>,
            required: true
        }
    },
    setup(props) {
        const timeOptions = [
            // {value: 0, text: 'Hour'},
            { value: 1, text: 'Day' },
            { value: 2, text: 'Month' },
            { value: 3, text: 'Year' },
        ]

        const durationSize = ref(2)

        const getDurationSize = (selectedSize: number): number => {
            switch (selectedSize) {
                case 0:
                    return 3600000;
                case 1:
                    return 86400000;
                case 2:
                    return 2628000000;
                case 3:
                    return 31536000000;
                default:
                    return 86400000;
            }
        }

        const productDuration = computed({
            get: () => {
                const divider = getDurationSize(durationSize.value)
                return props.productVariant.duration / divider
            },
            set: (value) => {
                const multiplier = getDurationSize(durationSize.value)
                props.productVariant.duration = value * multiplier
            }
        })

        const setProdID = (e: string) => {
            props.productVariant.source.shopify = { productId: '', variantId: '' }
            props.productVariant.source.shopify.productId = e
        }

        const setVarID = (e: string) => {
            props.productVariant.source.shopify = { productId: '', variantId: '' }
            props.productVariant.source.shopify.variantId = e
        }


        return {
            timeOptions,
            productDuration,
            durationSize,
            setProdID,
            setVarID
        }
    }
})
