









import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
    name: "UploadToast",
    props: {
        success: Boolean,
        counter: Number
    },
    setup(props) {
        const successVariant = computed(() => {
            if (props.success) return 'success'
            return 'warning'
        })

        return {
            successVariant
        }
    }
})
