import {
    License,
    LicenseListItem,
    PaymentType,
    Product,
    ProductVariant,
    SplashUserType,
    User, Voucher
} from "@/types/typeDefinitions";

export function useEmptyObject() {
    const now = Date.now().toString()

    const newUser = (): User => {
        return {
            email: '',
            password: '',
            type: SplashUserType.student,
            newsletter: false,
            licenses: [],
            // [{
            // productId: '',
            // variantId: '',
            // purchaseDate: now,
            // expirationDate: now,
            // autoRenew: false,
            // isActive: false,
            // type: PaymentType.APPLE_IAP
            // }],
            isAdmin: false,
            languageZone: 'en-GB'
        }
    }

    const newProduct = (): Product => {
        return {
            _id: '',
            productId: '',
            name: '',
            variants: [
                newVariant()
            ],
            updatedAt: now,
            createdAt: now
        }
    }

    const newVariant = (): ProductVariant => {
        return {
            id: '',
            name: '',
            duration: 0,
            price: '',
            source: {
                shopify: {
                    productId: '',
                    variantId: ''
                },
                android: null,
                apple: null,
                productKey: null
            }
        }
    }

    const newLicense = (): License => {
        return {
            productId: '',
            variantId: '',
            purchaseDate: now,
            expirationDate: now,
            autoRenew: false,
            isActive: false,
            type: PaymentType.APPLE_IAP
        }
    }

    const newLicenseListItem = (): LicenseListItem => {
        return {
            productId: '',
            variantId: '',
            name: '',
            purchaseDate: now,
            expirationDate: null,
            autoRenew: false,
            isActive: false,
            type: PaymentType.APPLE_IAP,
            features: null,
            link: '',
            picturePath: 'planet.webp',
            appLink: '',
            eBookLink: ''
        }
    }

    const newVoucher = (): Voucher => {
        return {
            key: '',
            variantId: '',
            productId: '',
            maxSessions: undefined,
            licenseId: undefined
        }
    }

    return {
        newProduct,
        newVariant,
        newLicense,
        newUser,
        newLicenseListItem,
        newVoucher
    }
}