






















































































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import EditProduct from "@/components/Modal/EditProduct.vue";
import { Product, ProductList } from "@/types/typeDefinitions";
import { useProductAPI } from "@/api_connectors/product";
import VoucherManagement from "@/views/admin/VoucherManagement.vue";
import CheckDelete from "@/components/Modal/CheckDelete.vue";
import DeleteToast from "@/components/Toast/DeleteToast.vue";
import FailedRequest from "@/components/Toast/FailedRequest.vue";
import SavedToast from "@/components/Toast/SavedToast.vue";
import SaveFailedToast from "@/components/Toast/SaveFailedToast.vue";
import { useToast } from "@/composables/useToast";
import usePagination from "@/composables/usePagination";

export default defineComponent({
    name: "ProductManagement",
    components: {
        SaveFailedToast,
        SavedToast,
        FailedRequest,
        DeleteToast,
        CheckDelete,
        VoucherManagement,
        EditProduct
    },
    setup() {
        const { deleteProduct } = useProductAPI()
        const { showToast, getTargetName } = useToast()
        const { getPaginatedList } = usePagination()

        interface ProductTableItem {
            productId: string;
            name: string;
            variants: string;
            editID: string | undefined;
            // deleteID: string | undefined;
        }

        let productList = reactive<Product[]>([])
        const tableItems = reactive<ProductTableItem[]>([]);
        const selectedProductID = ref('')
        const target = ref('')
        const requestTarget = ref('saveProduct')
        const filter = ref('')
        const sortBy = ref('email')
        const sortDesc = ref(false)
        const fields = [
            { key: 'productId' },
            { key: 'name' },
            { key: 'variants' },
            { key: 'editID', label: '' },
            { key: 'deleteID', label: '' }
        ]
        const currentPage = ref(1);
        const perPage = ref(10);
        const rows = ref(0);
        const pageOptions = [
            { text: '10', value: 10 },
            { text: '25', value: 25 },
            { text: '50', value: 50 },
        ]

        const update = async (): Promise<void> => {
            await updateProductPagination()
            //ToDO: find better solution for reactive
            // productList.splice(0)
            // productList.push(...(await getProductList()))
            //
            // tableItems.length = 0;
            // // productList = reactive<Product[]>()
            //
            // for (const product of productList) {
            //     const variantNames = Array<string>()
            //     for (const variant of product.variants) {
            //         if (variant.name === '') continue;
            //         variantNames.push(variant.name)
            //     }
            //     tableItems.push({
            //         productId: product.productId,
            //         name: product.name,
            //         variants: variantNames.join(', ') + ` ( ${product.variants.length} )`,
            //         editID: product.id,
            //         // deleteID: product.id
            //     })
            // }
            // rows.value = tableItems.length;
            // console.log(productList)
        }

        const openNewProductDialog = (openModal: Function): void => {
            selectedProductID.value = 'new';
            openModal();
        }

        const changeTargetProduct = (eventTarget: string) => {
            target.value = eventTarget
            const productTargets = {
                deleteTarget: 'deleteProduct',
                editTarget: 'saveProduct',
                createTarget: 'createProduct'
            }
            requestTarget.value = getTargetName(eventTarget, productTargets)
        }

        const deleteProductFromList = async (): Promise<void> => {
            const response = await deleteProduct(selectedProductID.value)

            if (response) showToast('delete-toast')
            else {
                changeTargetProduct('delete')
                showToast('failed-request')
            }

            await update();
        }

        const convertProductList = (list: ProductList[]) => {
            // console.log("Productlist", list)
            tableItems.splice(0)
            for (const product of list) {
                const { productId, name, id, variants } = product

                const variantNames = Array<string>()
                for (const variant of variants) {
                    if (variant.name === '') continue;
                    variantNames.push(variant.name)
                }

                tableItems.push({
                    productId: productId,
                    name: name,
                    variants: variantNames.join(', ') + ` ( ${product.variants.length} )`,
                    editID: id,
                    // deleteID: product.id
                })
            }
        }

        const updateProductPagination = async (page?: number) => {
            // console.log(page)
            if (!page) page = currentPage.value
            const offset = (page - 1) * perPage.value
            const { list, maxCount } =
                await getPaginatedList('/api/admin/product/productList', filter.value, offset, perPage.value)
            convertProductList(list)
            // console.log(list)
            rows.value = maxCount
        }

        onMounted(() => update())

        return {
            filter,
            sortBy,
            sortDesc,
            fields,
            selectedProductID,
            productList,
            currentPage,
            perPage,
            rows,
            pageOptions,
            tableItems,
            target,
            requestTarget,
            openNewProductDialog,
            update,
            deleteProductFromList,
            changeTargetProduct,
            updateProductPagination
        }
    }
})
