























































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { VoucherAPI, VoucherList } from "@/types/typeDefinitions";
import UploadToast from "@/components/Toast/UploadToast.vue";
import EditVoucher from "@/components/Modal/EditVoucher.vue";
import usePagination from "@/composables/usePagination";

export default defineComponent({
    name: "VoucherManagement",
    components: { EditVoucher, UploadToast },
    setup() {
        const { getPaginatedList } = usePagination()

        const fields = [
            { key: 'voucherKey', sortable: true, thClass: 'voucher-key-col' },
            { key: 'productId', sortable: true, thClass: 'product-id-col' },
            { key: 'licenseId', sortable: true, thClass: 'license-id-col' },
            { key: 'user', sortable: true, thClass: 'user-col' },
            // { key: 'edit', sortable: false }
        ]
        const filter = ref('')
        const redeemed = ref(false)
        const sortBy = ref('email')
        const sortDesc = ref(false)
        const currentPage = ref(1);
        const perPage = ref(10);
        const rows = ref(0);
        const pageOptions = [
            { text: '10', value: 10 },
            { text: '25', value: 25 },
            { text: '50', value: 50 },
        ]

        let voucherList = reactive<VoucherList[]>([{
            voucherKey: '',
            productId: '',
            licenseId: '',
            user: '',
            edit: ''
        }])

        const selectedVoucherUser = ref<string>()

        const update = async (): Promise<void> => {
            await updateVoucherPagination()
        }

        // const setSelectedVoucherUser = (voucherKey: string) => {
        //     selectedVoucherUser.value = voucherList.find(voucher => voucher.voucherKey === voucherKey)?.user
        // }

        const convertVoucherList = (list: VoucherAPI[]) => {
            voucherList.splice(0)
            for (const item of list) {
                let email = ''
                // const { key, productId, licenseId, user, id } = item
                const { key, productId, licenseId, user } = item
                if (user) email = user.email

                voucherList.push({
                    voucherKey: key,
                    productId: productId,
                    licenseId: licenseId,
                    user: email,
                    // edit: id
                })
            }
        }

        const updateVoucherPagination = async (page?: number) => {
            if (!page) page = currentPage.value
            const offset = (page - 1) * perPage.value
            const {
                list,
                maxCount
            } = await getPaginatedList('api/admin/voucher/voucherList', filter.value, offset, perPage.value, { redeemed: redeemed.value })
            convertVoucherList(list)
            rows.value = maxCount
        }

        onMounted(() => update())

        return {
            filter,
            sortBy,
            sortDesc,
            voucherList,
            fields,
            perPage,
            currentPage,
            pageOptions,
            rows,
            selectedVoucherUser,
            redeemed,
            // setSelectedVoucherUser,
            updateVoucherPagination,
        }
    }
})
