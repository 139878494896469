















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
    name: "SaveFailedToast",
    props: {
        target: String,
        object: String,
        addedInfo: String
    }
})
