import { VoucherList } from "@/types/typeDefinitions";
import { useApi } from "@/composables/useApi";

export default function usePagination() {

    const { api } = useApi()

    const getPaginatedList = async (route: string, searchKey?: string, offset?: number, limit?: number, conditions?: Object): Promise<{ list: any[], maxCount: number }> => {
        const { list, maxCount } = await apiRequest(route, searchKey, offset, limit, conditions)
        return { list: list, maxCount: maxCount }
    }

    const apiRequest = async (route: string, searchKey?: string, offset?: number, limit?: number, conditions?: Object): Promise<{ list: any[]; maxCount: number }> => {

        const response = await api.get(
            route,
            {
                params:
                    {
                        searchKey: searchKey,
                        offset: offset,
                        limit: limit,
                        ...conditions
                    }
            })
            .catch(() => {
                return undefined
            })

        // console.log(response)

        if (!response) return { list: [], maxCount: 0 }
        return { list: response.data.list as VoucherList[], maxCount: response.data.maxCount }
    }


    return {
        getPaginatedList
    }
}
